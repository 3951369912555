import { useTranslation } from "react-i18next";
import ImageCollage from "./ImageCollage";
import ParallaxEffect from "../../components/animations/ParallaxEffect";
import { translations } from "locales/translations";
import { FaArrowRight } from "react-icons/fa";
import { downloadTheApp } from "utils/services";

const TheAppSection = () => {
  const { t } = useTranslation();
  return (
    <ParallaxEffect translateY={[0, 400]}>
      <div className="container h-[120vh] max-w-full p-4 md:p-20 min-h-screen flex">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-4 sticky top-[10vh] content-start md:content-normal">
          <div className="text-center md:text-left">
            <h4 className="text-brand-text-muted font-mulish  font-semibold text-xl">
              {t(translations.common.theApp)}
            </h4>

            <h1 className="font-mulish font-extrabold text-3xl md:text-6xl my-12">
              {t(translations.titles.magicMemories)}
            </h1>
            <p className="font-mulish font-light text-base ">
              {t(translations.contents.theAppDescription)}
            </p>

            <div className="w-full mt-4 flex justify-center md:justify-start">
              <button
                className="flex px-4 gap-5 items-center bg-brand-secondary h-12 w-56 rounded-3xl"
                onClick={downloadTheApp}
              >
                {t(translations.common.downloadApp)}
                {<FaArrowRight />}
              </button>
            </div>
          </div>
          <ImageCollage />
        </div>
      </div>
    </ParallaxEffect>
  );
};

export default TheAppSection;
