import { useTranslation } from "react-i18next";
import { translations } from "locales/translations";
import { Paragraph, Title, BulletPoints } from "components/Paragraph";
import FairyContainer from "components/FairyContainer";
import { useEffect } from "react";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  const getBulletPoints = (module): string[] => {
    const validKeys = Object.keys(module).filter((k) => k.includes("point"));
    const list = validKeys.map((k) => t(module[k]));
    return list ? list : [];
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="pt-20 md:p-60 md:pb-5 overflow-x-hidden">
      <FairyContainer>
        <>
          <section>
            <Title text={t(translations.privacyPolicy.mainDescription.title)} />
            <Paragraph
              text={t(translations.privacyPolicy.mainDescription.paragraph1)}
            />
            <Paragraph
              text={t(translations.privacyPolicy.mainDescription.paragraph2)}
            />
            <Paragraph
              text={t(translations.privacyPolicy.mainDescription.paragraph3)}
            />
            <Paragraph
              text={t(translations.privacyPolicy.mainDescription.paragraph4)}
            />
          </section>

          <section>
            <Title
              text={t(translations.privacyPolicy.informationCollected.title)}
            />
            <Paragraph
              text={t(
                translations.privacyPolicy.informationCollected.paragraph1
              )}
            />
          </section>

          <section>
            <Title text={t(translations.privacyPolicy.logData.title)} />
            <Paragraph
              text={t(translations.privacyPolicy.logData.paragraph1)}
            />
          </section>

          <section>
            <Title text={t(translations.privacyPolicy.cookies.title)} />
            <Paragraph
              text={t(translations.privacyPolicy.cookies.paragraph1)}
            />
            <Paragraph
              text={t(translations.privacyPolicy.cookies.paragraph2)}
            />
          </section>

          <section>
            <Title
              text={t(translations.privacyPolicy.serviceProviders.title)}
            />
            <Paragraph
              text={t(
                translations.privacyPolicy.serviceProviders.thirdParyCompanies
                  .title
              )}
            />
            <BulletPoints
              points={getBulletPoints(
                translations.privacyPolicy.serviceProviders.thirdParyCompanies
              )}
              keyBase="thirdPartyCompanies"
            />
            <Paragraph
              text={t(translations.privacyPolicy.serviceProviders.paragraph1)}
            />
          </section>

          <section>
            <Title text={t(translations.privacyPolicy.security.title)} />
            <Paragraph
              text={t(translations.privacyPolicy.security.paragraph1)}
            />
          </section>

          <section>
            <Title text={t(translations.privacyPolicy.linkToOthers.title)} />
            <Paragraph
              text={t(translations.privacyPolicy.linkToOthers.paragraph1)}
            />
          </section>

          <section>
            <Title text={t(translations.privacyPolicy.childrenPrivacy.title)} />
            <Paragraph
              text={t(translations.privacyPolicy.childrenPrivacy.paragraph1)}
            />
          </section>

          <section>
            <Title text={t(translations.privacyPolicy.changes.title)} />
            <Paragraph
              text={t(translations.privacyPolicy.changes.paragraph1)}
            />
            <Paragraph
              text={t(translations.privacyPolicy.changes.paragraph2)}
            />
          </section>

          <section>
            <Title text={t(translations.privacyPolicy.contactUs.title)} />
            <Paragraph
              text={t(translations.privacyPolicy.contactUs.paragraph1)}
            />
          </section>
        </>
      </FairyContainer>
    </div>
  );
};

export default PrivacyPolicy;
