type ImageCardProps = {
  path: string;
  title: string;
  description: string;
};

const ImageCard = ({ title, description, path }: ImageCardProps) => {
  return (
    <div className="w-full flex justify-center p-2 ">
      <div className="drop-shadow-imageCard rounded-lg	">
        <img src={path} alt={title} className="h-[75vh] ml-auto mr-auto" />
        <div className="description-block max-h-[20vh] min-h-[10vh] min-w-[60vw] mt-2 hidden">
          <h2 className="text-center font-mulish font-bold text-3xl text-brand-primary">
            {title}
          </h2>
          <p className="mt-2 text-brand-text-light font-mulish text-base text-center font-light">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ImageCard;
