import { useTranslation } from "react-i18next";
import { AvailableLanguages } from "../utils/enums";
import classNames from "classnames";
import { translations } from "../locales/translations";

type LanguageButtonProps = {
  language: AvailableLanguages;
  onClick?: () => void;
};

const LanguageButton = ({ language, onClick }: LanguageButtonProps) => {
  const { t, i18n } = useTranslation();
  const isSelected = i18n.language === language;
  return (
    <button
      onClick={onClick}
      className={classNames(
        "bg-brand-primary rounded-md px-3 py-2 text-sm font-medium hover:text-brand-text-light",
        isSelected ? "text-brand-secondary" : "text-brand-text-dark"
      )}
    >
      {t(translations.languages[language])}
    </button>
  );
};

export default LanguageButton;
