import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, EffectCoverflow, Navigation } from "swiper";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./carousel.css";
import ImageCard from "./ImageCard";
import Filters from "../assets/carouselImages/FiltersView.png";
import History from "../assets/carouselImages/HistoryView.png";
import TeethView from "../assets/carouselImages/TeethView.png";
import KidsPortal from "../assets/carouselImages/KidsView.png";
import Messages from "../assets/carouselImages/MessagesView.png";
import ParentPortal from "../assets/carouselImages/ParentPortalView.png";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import ParallaxEffect from "./animations/ParallaxEffect";
import { useTranslation } from "react-i18next";
import { translations } from "locales/translations";
import { useAnimation, useInView } from "framer-motion";
import { useEffect, useRef } from "react";
import WavyText from "./animations/WaveText";
const slider = [
  {
    path: Filters,
    title: translations.carousel.filterCard,
    description: translations.carousel.filterCardDescription,
  },
  {
    path: History,
    title: translations.carousel.historyCard,
    description: translations.carousel.historyCardDescription,
  },
  {
    path: TeethView,
    title: translations.carousel.teethCard,
    description: translations.carousel.teethCardDescription,
  },
  {
    path: KidsPortal,
    title: translations.carousel.kidsCard,
    description: translations.carousel.kidsCardDescription,
  },
  {
    path: Messages,
    title: translations.carousel.messagesCard,
    description: translations.carousel.messagesCardDescription,
  },

  {
    path: ParentPortal,
    title: translations.carousel.parentCard,
    description: translations.carousel.parentCardDescription,
  },
];

const Carousel = () => {
  const { t } = useTranslation();

  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });
  const controls = useAnimation();

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [isInView]);

  return (
    <div ref={ref} className="h-[150vh]">
      <ParallaxEffect>
        <div className="w-full  mt-8 flex flex-col items-center  justify-center">
          <WavyText
            isVisible={isInView}
            className="max-w-4xl font-mulish font-semibold text-2xl  md:text-5xl text-brand-primary text-center mb-8 flex justify-center flex-wrap"
            text={t(translations.titles.collectingTeeth)}
            customStyles={{
              4: "text-brand-secondary",
              5: "text-brand-secondary",
            }}
          />
        </div>
      </ParallaxEffect>
      <ParallaxEffect translateY={[0, 500]}>
        <div className="carousel">
          <Swiper
            className="myswiper"
            modules={[Pagination, EffectCoverflow, Navigation]}
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
              slideShadows: false,
            }}
            loop={true}
            pagination={{ clickable: true }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            allowTouchMove={false}
            breakpoints={{
              768: {
                slidesPerView: 2,
              },
              1070: {
                slidesPerView: 3,
              },
            }}
          >
            {slider.map((data) => (
              <SwiperSlide key={data.title} className="flex justify-center">
                <ImageCard
                  path={data.path}
                  title={t(data.title)}
                  description={t(data.description)}
                />
              </SwiperSlide>
            ))}

            <div className="slider-controler">
              <div className="swiper-button-prev slider-arrow w-12 h-12 rounded-full bg-brackground">
                <MdKeyboardArrowLeft className="w-12 h-12" />
              </div>
              <div className="swiper-button-next slider-arrow w-12 h-12 rounded-full bg-brackground">
                <MdKeyboardArrowRight className="w-12 h-12 " />
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </Swiper>
        </div>
      </ParallaxEffect>
    </div>
  );
};

export default Carousel;
