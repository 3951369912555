import { useTranslation } from "react-i18next";
import Carousel from "../components/Carousel";
import FullLogo from "../assets/FullLogo.png";
import Stars from "../assets/Stars.png";
import Fairy2 from "../assets/TF Pose 02.png";
import Mouse from "../assets/mouse.png";
import ButtonWithImage from "../components/ButtonWithImage";
import TransparentButton from "../components/TransparentButton";
import TheAppSection from "./TheAppSection/index";
import ContactUsSection from "./ContactUsSection";
import Reveal from "../components/animations/Reveal";
import StarAnimation from "../components/animations/Stars";
import ParallaxEffect from "../components/animations/ParallaxEffect";
import { translations } from "locales/translations";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { downloadTheApp } from "utils/services";

const HomePage = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState<string>();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <div className="min-h-full -mb-40 after:content-[''] after:block after:h-40">
      <div
        id="section1"
        className="min-h-[100vh] bg-brand-primary flex justify-center items-center relative"
      >
        <img className="-mt-16" src={FullLogo} alt="Tooth Magic Memory" />
        <StarAnimation />
        <div
          className="h-full w-[100%] absolute"
          style={{ backgroundImage: `url(${Stars})` }}
        />
        <div className="mt-5 absolute bottom-10 w-full flex justify-center">
          <ParallaxEffect>
            <div className="mt-5 absolute bottom-20 w-full m-auto gap-5 grid md:grid-cols-2">
              <ButtonWithImage
                src={language === "en" ? Fairy2 : Mouse}
                text={t(translations.common.downloadApp)}
                onClick={downloadTheApp}
              />
              <TransparentButton
                text={t(translations.common.checkFeatures)}
                onClick={() => {
                  navigate("#section2");
                }}
              />
            </div>
          </ParallaxEffect>
        </div>
      </div>
      <Reveal id="section2">
        <Carousel />
      </Reveal>
      <Reveal id="section3">
        <TheAppSection />
      </Reveal>
      <div id="section4">
        <ContactUsSection />
      </div>
    </div>
  );
};

export default HomePage;
