export type NavTabs = "home" | "contactUs" | "features" | "theApp";

export const navigations: Record<NavTabs, string> = {
  home: "home",
  contactUs: "contactUs",
  features: "features",
  theApp: "theApp",
};

export const routes: Record<NavTabs, string> = {
  home: "/",
  features: "/#section2",
  theApp: "/#section3",
  contactUs: "/#section4",
};

export const AppName = "Tooth Magic Memory";

export const AppLink =
  "https://apps.apple.com/us/app/tooth-magic-memory/id6462195780";
