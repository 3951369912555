import classNames from 'classnames';
import { motion } from 'framer-motion';

const getRandomPosition = () => {
  const containerWidth = window.innerWidth;
  const containerHeight = window.innerHeight;
  const x = Math.random() * containerWidth;
  const y = Math.random() * containerHeight;
  return { x, y };
};

const getRandomSize = (minSize = 2, maxSize = 6) => {
  const size = Math.floor(Math.random() * (maxSize - minSize + 1)) + minSize;
  return size;
};

const getRandomBlur = () => {
  const minBlur = 0.5;
  const maxBlur = 3;
  const blur = (Math.random() * (maxBlur - minBlur) + minBlur).toFixed(1);
  return blur;
};

const StarAnimation = () => {
  const starVariants = {
    initial: {
      opacity: 0,
      scale: 0,
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div className="star-container absolute top-0 left-0 w-full h-full overflow-hidden">
      {[...Array(50)].map((_, index) => {
        const size = getRandomSize(2, 10);
        const blur = getRandomBlur();
        return (
          <motion.div
            key={index}
            className={classNames('star absolute bg-white rounded-[50%]', getRandomSize())}
            variants={starVariants}
            initial="initial"
            animate={{
              opacity: [1, 0],
              scale: 1,
            }}
            style={{
              ...getRandomPosition(),
              width: `${size}px`,
              height: `${size}px`,
              filter: `blur(${blur}px)`,
            }}
            transition={{
              duration: 3,
              repeat: Infinity,
              repeatType: 'reverse',
              delay: getRandomSize(0, 5),
            }}
          />
        );
      })}
    </div>
  );
};

export default StarAnimation;
