import { useTranslation } from "react-i18next";
import { translations } from "locales/translations";
import { Paragraph, Title, BulletPoints } from "components/Paragraph";
import FairyContainer from "components/FairyContainer";
import { useEffect } from "react";

const TermsAndConditions = () => {
  const { t } = useTranslation();

  const getBulletPoints = (module): string[] => {
    const validKeys = Object.keys(module).filter((k) => k.includes("point"));
    const list = validKeys.map((k) => t(module[k]));
    return list ? list : [];
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="pt-20 md:p-60 md:pb-5 overflow-x-hidden">
      <FairyContainer>
        <>
          <section>
            <Title
              text={t(translations.termsAndConditions.mainDescription.title)}
            />
            <Paragraph
              text={t(
                translations.termsAndConditions.mainDescription.paragraph1
              )}
            />
            <Paragraph
              text={t(
                translations.termsAndConditions.mainDescription.paragraph2
              )}
            />
            <Paragraph
              text={t(
                translations.termsAndConditions.mainDescription.paragraph3
              )}
            />
            <Paragraph
              text={t(
                translations.termsAndConditions.mainDescription.paragraph4
              )}
            />
          </section>

          <section>
            <Title text={t(translations.termsAndConditions.cookies.title)} />
            <Paragraph
              text={t(translations.termsAndConditions.cookies.paragraph1)}
            />
            <Paragraph
              text={t(translations.termsAndConditions.cookies.paragraph2)}
            />
          </section>

          <section>
            <Title text={t(translations.termsAndConditions.license.title)} />
            <Paragraph
              text={t(translations.termsAndConditions.license.paragraph1)}
            />
            <Paragraph
              text={t(translations.termsAndConditions.license.paragraph2)}
            />
            <Paragraph
              text={t(translations.termsAndConditions.license.youMustNot.title)}
            />
            <BulletPoints
              points={getBulletPoints(
                translations.termsAndConditions.license.youMustNot
              )}
              keyBase="youMustNot"
            />
            <Paragraph
              text={t(translations.termsAndConditions.license.paragraph3)}
            />
            <Paragraph
              text={t(translations.termsAndConditions.license.paragraph4)}
            />
            <Paragraph
              text={t(translations.termsAndConditions.license.youWarrant.title)}
            />
            <BulletPoints
              points={getBulletPoints(
                translations.termsAndConditions.license.youWarrant
              )}
              keyBase="youWarrant"
            />
            <Paragraph
              text={t(translations.termsAndConditions.license.paragraph5)}
            />
          </section>

          <section>
            <Title
              text={t(translations.termsAndConditions.Hyperlinking.title)}
            />
            <Paragraph
              text={t(
                translations.termsAndConditions.Hyperlinking.organization.title
              )}
            />
            <BulletPoints
              points={getBulletPoints(
                translations.termsAndConditions.Hyperlinking.organization
              )}
              keyBase="organization"
            />
            <Paragraph
              text={t(translations.termsAndConditions.Hyperlinking.paragraph1)}
            />
            <Paragraph
              text={t(
                translations.termsAndConditions.Hyperlinking.linkRequests.title
              )}
            />
            <BulletPoints
              points={getBulletPoints(
                translations.termsAndConditions.Hyperlinking.linkRequests
              )}
              keyBase="linkRequests"
            />
            <Paragraph
              text={t(translations.termsAndConditions.Hyperlinking.paragraph2)}
            />

            <Paragraph
              text={t(
                translations.termsAndConditions.Hyperlinking
                  .approvedOrganizations.title
              )}
            />
            <BulletPoints
              points={getBulletPoints(
                translations.termsAndConditions.Hyperlinking
                  .approvedOrganizations
              )}
              keyBase="approvedOrganizations"
            />
            <Paragraph
              text={t(translations.termsAndConditions.Hyperlinking.paragraph3)}
            />
          </section>

          <section>
            <Title text={t(translations.termsAndConditions.iFrame.title)} />
            <Paragraph
              text={t(translations.termsAndConditions.iFrame.paragraph1)}
            />
          </section>

          <section>
            <Title
              text={t(translations.termsAndConditions.contentLiability.title)}
            />
            <Paragraph
              text={t(
                translations.termsAndConditions.contentLiability.paragraph1
              )}
            />
          </section>

          <section>
            <Title
              text={t(
                translations.termsAndConditions.reservationOfRights.title
              )}
            />
            <Paragraph
              text={t(
                translations.termsAndConditions.reservationOfRights.paragraph1
              )}
            />
          </section>

          <section>
            <Title
              text={t(translations.termsAndConditions.removalOfLinks.title)}
            />
            <Paragraph
              text={t(
                translations.termsAndConditions.removalOfLinks.paragraph1
              )}
            />
            <Paragraph
              text={t(
                translations.termsAndConditions.removalOfLinks.paragraph2
              )}
            />
          </section>

          <section>
            <Title text={t(translations.termsAndConditions.disclaimer.title)} />
            <Paragraph
              text={t(translations.termsAndConditions.disclaimer.paragraph1)}
            />
            <BulletPoints
              points={getBulletPoints(
                translations.termsAndConditions.disclaimer.nothingWill
              )}
              keyBase="nothingWill"
            />
            <Paragraph
              text={t(translations.termsAndConditions.disclaimer.paragraph2)}
            />
            <Paragraph
              text={t(translations.termsAndConditions.disclaimer.paragraph3)}
            />
          </section>
        </>
      </FairyContainer>
    </div>
  );
};

export default TermsAndConditions;
