import { useEffect, useRef } from 'react';
import { motion, useInView, useAnimation } from 'framer-motion';

import Img1 from '../../assets/appImages/card1.png';
import Img2 from '../../assets/appImages/card2.png';
import Img3 from '../../assets/appImages/card3.png';
import Img4 from '../../assets/appImages/card4.png';
import Img5 from '../../assets/appImages/card5.png';

const ImageCollage = () => {
  const currentRef = useRef(null);
  const isInView = useInView(currentRef, { once: false });
  const controls = useAnimation();

  useEffect(() => {
    if (isInView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [isInView]);

  const rotationVariant = {
    hidden: { opacity: 0, y: 75 },
    visible: {
      opacity: 1,
      scale: [0.3, 1],
      rotate: [0, 12],
    },
  };

  const scaleVariant = {
    hidden: { opacity: 0, y: 75 },
    visible: {
      opacity: 1,
      scale: [0.3, 1],
    },
  };

  const reverseRotationVariant = {
    hidden: { opacity: 0, y: 75 },
    visible: {
      opacity: 1,
      scale: [0.3, 1],
      rotate: [0, -12],
    },
  };

  return (
    <div ref={currentRef} className="-mt-[10%]">
      <div className="grid grid-cols-3">
        <div className="relative">
          <motion.img
            variants={rotationVariant}
            initial="hidden"
            animate={controls}
            transition={{ duration: 1 }}
            alt=""
            className="w-full absolute -right-[25%] z-10"
            src={Img1}
          />
        </div>
        <div className="relative">
          <motion.img
            variants={scaleVariant}
            initial="hidden"
            animate={controls}
            transition={{ duration: 1, delay: 0.2 }}
            alt=""
            className="w-full"
            src={Img2}
          />
        </div>
        <div className="relative">
          <motion.img
            variants={scaleVariant}
            initial="hidden"
            animate={controls}
            transition={{ duration: 1, delay: 0.4 }}
            alt=""
            className="w-full absolute -left-[25%] z-10"
            src={Img5}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 ">
        <div className="relative">
          <motion.img
            variants={scaleVariant}
            initial="hidden"
            animate={controls}
            transition={{ duration: 1, delay: 0.6 }}
            className="w-full ml-[50%] -top-[20%] -rotate-12"
            src={Img4}
            alt=""
          />
        </div>
        <div />
        <div className="relative">
          <motion.img
            variants={reverseRotationVariant}
            initial="hidden"
            animate={controls}
            transition={{ duration: 1, delay: 0.8 }}
            alt=""
            className="w-full -top-[15%] -ml-[65%]"
            src={Img3}
          />
        </div>
      </div>
    </div>
  );
};

export default ImageCollage;
