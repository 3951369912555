import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { translations } from "../locales/translations";
import ParallaxEffect from "./animations/ParallaxEffect";
import { AppLink } from "utils/const";

type link = {
  path: string;
  textKey: string;
  target?: string;
};

export default function Footer() {
  const { t } = useTranslation();
  const links: link[] = [
    {
      path: "/tyc",
      textKey: translations.common.termsAndConditions,
    },
    {
      path: "/privacy",
      textKey: translations.common.privacyPolicy,
    },
    {
      path: AppLink,
      textKey: translations.common.downloadApp,
      target: "_blank",
    },
  ];

  return (
    <ParallaxEffect translateY={[0, 400]}>
      <footer className="flex text-white bg-brand-primary bottom-0 w-full flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12 border-t border-blue-gray-50 py-6 text-center md:justify-between">
        <div className="w-full flex justify-between px-8">
          <ul className="flex flex-wrap items-center gap-y-2 gap-x-8">
            {links.map((link) => (
              <li key={link.textKey}>
                <Link
                  to={link.path}
                  target={link.target}
                  className="font-normal transition-colors hover:text-brand-secondary focus:text-brand-secondary"
                >
                  {t(link.textKey)}
                </Link>
              </li>
            ))}
          </ul>
          <p color="blue-gray" className="font-normal">
            {t(translations.common.magicRights)}
          </p>
        </div>
      </footer>
    </ParallaxEffect>
  );
}
