import classNames from "classnames";
import { MouseEventHandler } from "react";

type ButtonWithImageProps = {
  src: string;
  text: string;
  className?: string;
  imageClasses?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const ButtonWithImage = ({
  src,
  text,
  className = "",
  imageClasses = "",
  onClick,
}: ButtonWithImageProps) => {
  return (
    <>
      <button
        className={classNames(
          "m-auto md:mr-5 relative bg-brand-secondary h-12 w-56 rounded-3xl",
          className
        )}
        onClick={onClick}
      >
        <img
          src={src}
          className={classNames("absolute -top-28 -left-16", imageClasses)}
        />
        {text}
      </button>
    </>
  );
};

export default ButtonWithImage;
