import { useTranslation } from "react-i18next";
import Fairy2 from "../assets/TF Pose 02.png";
import Mouse from "../assets/mouse.png";
import { useEffect, useState } from "react";
import classNames from "classnames";

const FairyContainer = ({ children }: { children: JSX.Element }) => {
  const [language, setlanguage] = useState<string>();
  const { i18n } = useTranslation();

  useEffect(() => {
    setlanguage(i18n.language);
  }, [i18n.language]);

  return (
    <div className="p-4 md:p-10 mt-12 md:mt-0 md:rounded-[32px] shadow-[0_10px_35px_0_rgba(0,20,49,0.15)] relative flex flex-col gap-8">
      <img
        src={language === "en" ? Fairy2 : Mouse}
        className={classNames(
          language === "en"
            ? "absolute -top-16 right-0 md:-top-28 md:right-0 md:h-auto h-24"
            : "absolute -top-10 -right-7 md:-top-20 w-48 md:w-auto md:-right-24 md:h-[12rem] "
        )}
      />
      {children}
    </div>
  );
};

export default FairyContainer;
