import { useTranslation } from "react-i18next";
import { AppName } from "../utils/const";
import { FaEnvelope } from "react-icons/fa";
import TextWithIcon from "../components/TextWithIcon";
import { translations } from "locales/translations";
import { FormEvent, RefObject, useRef } from "react";
import ParallaxEffect from "components/animations/ParallaxEffect";

const ContactUsSection = () => {
  const { t } = useTranslation();
  const nameRef = useRef<HTMLInputElement>(null);
  const messageRef = useRef<HTMLTextAreaElement>(null);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const name = nameRef.current?.value;
    const message = messageRef.current?.value;
    let params = name || message ? "?" : "";
    if (name) params += `subject=${encodeURIComponent(name)}`;
    if (message)
      params += `${name ? "&" : ""}body=${encodeURIComponent(message)}`;
    window.location.href = `mailto:Info@toothmagicmemory.com${params}`;
  };

  return (
    <ParallaxEffect translateY={[0, 400]}>
      <div className="min-h-[100vh] h-[300%]  bg-brand-secondary relative">
        <div className="container max-w-[100vw]">
          <div className="grid md:grid-cols-2">
            <div className="md:px-5 overflow-hidden break-words flex flex-col justify-center mt-24 items-center max-w-[vw] text-center md:text-left">
              <h1 className="md:pl-6 font-mulish font-extrabold text-2xl  md:text-5xl text-background">
                {t(translations.contents.loveToHear)}
              </h1>
              <div className="mt-10 max-w-[100vw] w-full">
                <TextWithIcon
                  text="Info@toothmagicmemory.com"
                  className="break-all"
                  icon={<FaEnvelope className="w-6 h-6" />}
                />
              </div>
              <h4 className="px-2 md:pl-6 mt-12 font-mulish font-extrabold text-2xl text-brand-primary">
                {t(translations.contents.alwaysLooking)}
              </h4>
              <h1 className="font-berkshire font-extrabold text-5xl w-72 text-center mt-32">
                {AppName}
              </h1>
            </div>
            <div className="md:px-5 flex items-center mt-24">
              <div className="form h-full bg-background md:rounded-3xl w-full p-5 relative">
                <form onSubmit={handleSubmit} className="w-full">
                  <div className="flex flex-col items-stretch gap-4 gap-y-4">
                    <input
                      ref={nameRef}
                      id="name"
                      autoComplete="false"
                      type="text"
                      className="w-full h-[70px] p-[10px] pl-5  border rounded-2xl"
                      placeholder={t(translations.common.name)}
                    />
                    <textarea
                      ref={messageRef}
                      id="message"
                      className="w-full h-[200px] p-[10px] pl-5  border rounded-2xl"
                      placeholder={t(translations.common.message)}
                    />
                    <div className="w-full flex justify-end mt-16">
                      <button
                        type="submit"
                        className="bg-brand-secondary w-full md:w-80 h-16 gap-2 rounded-full"
                      >
                        {t(translations.common.sendMessage)}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ParallaxEffect>
  );
};

export default ContactUsSection;
