import { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import classNames from "classnames";

type RevealProps = {
  children: JSX.Element;
  className?: string;
  transitionProps?: object;
  id?: string;
};

const Reveal = ({
  id,
  className = "",
  children,
  transitionProps = { duration: 0.5 },
}: RevealProps) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });
  const controls = useAnimation();

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [isInView]);
  return (
    <div
      ref={ref}
      id={id}
      className={classNames("relative w-full ", className)}
    >
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 75 },
          visible: {
            opacity: 1,
            y: 0,
          },
        }}
        initial="hidden"
        animate={controls}
        transition={transitionProps}
      >
        {children}
      </motion.div>
    </div>
  );
};

export default Reveal;
