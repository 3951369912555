import Vector from "assets/Vector.svg";
import { MouseEventHandler } from "react";

const TransparentButton = ({
  text,
  onClick,
}: {
  text: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}) => {
  return (
    <button
      onClick={onClick}
      className="flex items-center justify-center gap-2 m-auto md:ml-5 border-none bg-transparent h-12 w-56 text-brand-secondary"
    >
      {text}
      <img className="w-4 h-4" src={Vector} />
    </button>
  );
};

export default TransparentButton;
