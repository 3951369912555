export const Paragraph = ({ text }: { text: string }) => (
  <p className="text-brand-text-light break-all font-mulish font-light text-xl">{text}</p>
);

export const Title = ({ text }: { text: string }) => (
  <h1 className="mb-4 leading-[58px] text-brand-primary font-mulish font-extrabold text-4xl">
    {text}
  </h1>
);

export const BulletPoints = ({
  points,
  keyBase,
}: {
  points: string[];
  keyBase: string;
}) => (
  <ul className="ml-5">
    {points?.map((point, index) => (
      <li
        key={`${keyBase}-${index}`}
        className="list-disc text-brand-text-light font-mulish font-light text-xl"
      >
        {point}
      </li>
    ))}
  </ul>
);
