import { Disclosure, Transition } from "@headlessui/react";
import LanguageButton from "./LanguageButton";
import { AvailableLanguages } from "../utils/enums";
import { useTranslation } from "react-i18next";
import { NavTabs, navigations, routes } from "../utils/const";
import { MdOutlineMenu, MdClose } from "react-icons/md";
import { translations } from "../locales/translations";
import { Link } from "react-router-dom";
import { useScroll, motion } from "framer-motion";
import { useState } from "react";

type navigation = {
  name: string;
  href: string;
  current: boolean;
};

export default function NavBar() {
  const { t, i18n } = useTranslation();
  const { scrollY } = useScroll();
  const [isHidden, setIsHidden] = useState(false);

  scrollY.on("change", (value) => {
    const prev = scrollY.getPrevious();
    if (!isHidden && value > prev && value > 30) {
      setIsHidden(true);
    } else if (isHidden && value < prev) {
      setIsHidden(false);
    }
  });

  const tabs = [
    {
      name: translations.common.home,
      href: routes[navigations.home as NavTabs],
      current: true,
    },
    {
      name: translations.common.features,
      href: routes[navigations.features as NavTabs],
      current: false,
    },
    {
      name: translations.common.theApp,
      href: routes[navigations.theApp as NavTabs],
      current: false,
    },
  ];

  const handleChangeLanguage = (language: string) => () => {
    i18n.changeLanguage(language).then((response) => {
      response;
    });
  };

  const variants = {
    /** this is the "visible" key and it's correlating styles **/
    visible: { opacity: 1, y: 0 },
    /** this is the "hidden" key and it's correlating styles **/
    hidden: { opacity: 0, y: -25 },
  };

  return (
    <motion.div
      variants={variants}
      animate={isHidden ? "hidden" : "visible"}
      transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.6 }}
      className="bg-brand-primary fixed z-50 left-0 right-0"
    >
      <Disclosure as="nav">
        {({ open }: { open: boolean }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 text-brand-text-dark">
              <div className="relative flex h-16 items-center justify-between">
                <div className="inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-brand-text-light hover:text-brand-secondary focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? <MdClose /> : <MdOutlineMenu />}
                  </Disclosure.Button>
                </div>
                <div className="flex items-center justify-center left-auto sm:items-stretch sm:justify-start">
                  <div className="flex space-x-4 z-50">
                    <LanguageButton
                      language={AvailableLanguages.ENG}
                      onClick={handleChangeLanguage(AvailableLanguages.ENG)}
                    />
                    <LanguageButton
                      language={AvailableLanguages.ESP}
                      onClick={handleChangeLanguage(AvailableLanguages.ESP)}
                    />
                  </div>
                </div>
                <div className="absolute z-50 inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <div className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4">
                      {tabs?.map((item: navigation) => (
                        <Link
                          key={item.name}
                          className={
                            "cursor-pointer text-brand-text-dark hover:text-brand-secondary rounded-md px-3 py-2 text-sm font-medium"
                          }
                          aria-current={item.current ? "page" : undefined}
                          to={item.href}
                        >
                          {t(item.name)}
                        </Link>
                      ))}
                      <Link
                        className="cursor-pointer text-brand-text bg-background rounded-full px-3 py-2 text-sm font-medium hover:text-brand-secondary"
                        to={routes[navigations.contactUs as NavTabs]}
                      >
                        {t(translations.common.contactUs)}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {tabs.map((item: navigation) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={
                      "text-brand-text-light hover:text-brand-secondary block rounded-md px-3 py-2 text-base font-medium"
                    }
                    aria-current={item.current ? "page" : undefined}
                  >
                    {t(item.name)}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </motion.div>
  );
}
