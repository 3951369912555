import { cloneElement } from "react";
import { motion, useScroll, useTransform, MotionValue } from "framer-motion";

function useParallax(
  value: MotionValue<number>,
  distances = [0, 300],
  breakpoints = [0, 1]
) {
  return useTransform(value, breakpoints, distances);
}

function ParallaxEffect({
  children,
  translateY = [0, 300],
  breakPoints = [0, 1],
}: {
  children: JSX.Element;
  translateY?: number[];
  breakPoints?: number[];
}) {
  const { scrollYProgress } = useScroll();
  const y = useParallax(scrollYProgress, translateY, breakPoints);

  const DynamicMotionComponent = motion(children.type);
  return cloneElement(<DynamicMotionComponent style={{ y }} />, {
    ...children.props,
  });
}

export default ParallaxEffect;
