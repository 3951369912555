import { FC, ReactNode } from "react";
import { motion, Variants, HTMLMotionProps } from "framer-motion";
import classNames from "classnames";

interface Props extends HTMLMotionProps<"div"> {
  text: string;
  delay?: number;
  duration?: number;
  isVisible: boolean;
  secondaryText?: string;
  customStyles?: Record<number, string>;
}

const Wrapper = ({
  children,
  customStyles,
  index,
}: {
  children: ReactNode;
  customStyles?: Record<number, string>;
  index: number;
}) => {
  // We'll do this to prevent wrapping of words using CSS
  return (
    <span
      className={classNames(
        "word-wrapper flex",
        customStyles && customStyles[index] ? customStyles[index] : ""
      )}
    >
      {children}
    </span>
  );
};

const WavyText: FC<Props> = ({
  text,
  secondaryText,
  delay = 0,
  duration = 0.025,
  isVisible = false,
  customStyles,
  ...props
}: Props) => {
  const container: Variants = {
    hidden: {
      opacity: 0,
    },
    visible: (i: number = 1) => ({
      opacity: 1,
      transition: { staggerChildren: duration, delayChildren: i * delay },
    }),
  };

  const child: Variants = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 200,
      },
    },
    hidden: {
      opacity: 0,
      y: 20,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 200,
      },
    },
  };

  //  Split each word of props.text into an array
  const splitWords = text.split(" ");

  // Create storage array
  const words: string[][] = [];

  // Push each word into words array
  for (const [, item] of splitWords.entries()) {
    words.push(item.split(""));
  }

  // Add a space ("\u00A0") to the end of each word
  words.map((word) => {
    return word.push("\u00A0");
  });

  return (
    <motion.h1
      variants={container}
      initial="hidden"
      animate={isVisible ? "visible" : "hidden"}
      {...props}
    >
      {words.map((word, index) => {
        return (
          // Wrap each word in the Wrapper component
          <Wrapper key={index} index={index} customStyles={customStyles}>
            {Array.from(words[index])
              .flat()
              .map((element, index) => {
                return (
                  <span
                    style={{
                      overflow: "hidden",
                      display: "inline-block",
                    }}
                    key={index}
                  >
                    <motion.span
                      style={{ display: "inline-block" }}
                      variants={child}
                    >
                      {element}
                    </motion.span>
                  </span>
                );
              })}
          </Wrapper>
        );
      })}
    </motion.h1>
  );
};

export default WavyText;
