import classNames from "classnames";

type TextWithIconProps = {
  text: string;
  icon: JSX.Element;
  className?: string;
};

const TextWithIcon = ({ icon, text, className = "" }: TextWithIconProps) => {
  return (
    <div className="flex items-center md:p-6 flex-wrap justify-center md:justify-start">
      {icon}
      <p
        className={classNames(
          "font-poppins text-xl ml-4 font-medium text-brand-primary break-words",
          className
        )}
      >
        {text}
      </p>
    </div>
  );
};

export default TextWithIcon;
